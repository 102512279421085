@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
  ### blobs 
*/

#ellipse1 {
  background-color: #FDD832;
  border-radius: 1651.438px;
  filter: blur(21rem);
  flex-shrink: 0;
  position: absolute;
  width: 50%;
  z-index: 0;
  height: 100%;
  top: -22%;
}

#ellipse2 {
  background-color: #662d91;
  border-radius: 1651.438px;
  filter: blur(21rem);
  flex-shrink: 0;
  position: absolute;
  width: 50%;
  z-index: 0;
  height: 50%;
  left: -22%;
  bottom: -109%;
}

#ellipse3 {
  background-color: #662d91;
  border-radius: 1651.438px;
  filter: blur(21rem);
  flex-shrink: 0;
  position: absolute;
  width: 50%;
  z-index: 0;
  height: 50%;
  bottom: 14%;
}

#ellipse4 {
  background-color: #662d91;
  border-radius: 1651.438px;
  filter: blur(19rem);
  flex-shrink: 0;
  position: absolute;
  width: 98%;
  z-index: 0;
  height: 50%;
}